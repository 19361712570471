@import "navbar";

@import "sidebar";

@import "social";

@import "button";

@import "businesscard";

@import "testimonials";

.app {
  background: $primary-color;
  color: $text-color;
  min-height: 100vh;
}

.content {
  position: relative;
  margin-left: $sidebar-width;
  @include media-breakpoint-down(md) {
    & {
      margin-left: 0;
    }
  }
  @include media-breakpoint-down(sm) {
    & {
      padding-top: 4.5rem;
    }
  }
}

.page {
  padding: 2rem;
  @include media-breakpoint-down(md) {
    & {
      padding: 4rem 2rem;
    }
  }
  @include media-breakpoint-down(sm) {
    & {
      padding: 0;
    }
  }
}

p {
  color: rgba($text-color, 0.7);
}
