.portfolio {
  position: relative;
  .portfolio-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-gap: 1rem;

    &.portfolio-page {
      grid-template-columns: repeat(2, 1fr);
      @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
      }
    }

    .portfolio-item {
      position: relative;
      // Border radius variable
      $portfolio-border-radius: 0.5rem;

      border-radius: $portfolio-border-radius;
      overflow: hidden;
      cursor: pointer;

      .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), transparent);
        transition: 0.5s;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          background: linear-gradient(0deg, rgba(0, 0, 0, 1), transparent);
          opacity: 0;
          transition: 0.5s;
        }
      }

      &:hover > .overlay::before {
        opacity: 1;
      }

      .background {
        max-width: 100%;
        padding-top: 50%;
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        @include media-breakpoint-down(sm) {
          & {
            padding-top: 50%;
          }
        }
      }

      .details {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        @include media-breakpoint-down(sm) {
          & {
            justify-content: flex-end;
            align-items: flex-start;
          }
        }
        .title {
          font-size: 1.5rem;
          font-family: $font-family-heading;
        }
        .technologies {
          font-size: 0.75rem;
          color: rgba($text-color, 0.8);
        }
      }
    }
  }
  .portfolio-button {
    padding: 1rem;
    text-align: center;
  }
  .portfolio-modal {
    position: fixed;
    top: 0;
    left: $sidebar-width;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.active {
      display: flex;
    }

    @include media-breakpoint-down(md) {
      align-items: flex-start;
      left: 0;
    }

    .portfolio-modal-content {
      width: 70%;
      margin: 1rem;
      border-radius: 0.5rem;
      overflow: hidden;
      cursor: initial;

      @include media-breakpoint-down(lg) {
        width: 80%;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        margin-top: 5rem;
      }
    }
  }
}

@import "portfoliosingle";
