body {
  background: $primary-color;
  font-family: $font-family;
}

h1 {
  font-size: 3rem;
  color: $text-color;
}
h2 {
  font-size: 1.5rem;
}
h3 {
  font-size: 1rem;
}
h4 {
  font-size: 0.75rem;
}
h5 {
  font-size: 0.5rem;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $font-family-heading;
  letter-spacing: 0.1rem;
}

hr {
  border-color: rgba($text-color, 0.1);
}

a {
  color: $text-color;
  transition: 0.5s;
  &:hover {
    color: rgba($text-color, 0.8);
    text-decoration: none;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.modal {
  cursor: pointer;
}

.modal-dialog {
  cursor: initial;
}

.modal-content,
.modal-body {
  padding: 0;
  border-radius: 0.5rem;
  overflow: hidden;
}

.carousel-control-prev,
.carousel-control-next {
  width: 10%;
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 30px;
    height: 30px;
  }
}

.alert-custom {
  display: flex;
  justify-content: space-between;
  .close-icon {
    cursor: pointer;
  }
}
