.button {
  display: inline-block;
  background: $primary-color;
  color: $text-color;
  text-align: center;
  font-weight: 500;
  padding: 0.375rem 0.75rem;
  margin-right: 0.25rem;
  border-radius: 0.25rem;
  transition: 0.5s background-color;
  border: 1px solid #fff;
  outline: none;
  cursor: pointer;
  &:hover {
    background: invert($primary-color);
    color: invert($text-color);
    text-decoration: none;
  }

  &.button-block {
    width: 100%;
  }
}
