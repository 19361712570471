// $primary-color: #151e29;
// $secondary-color: #3b7eeb;
// $text-color: #ffffff;

//Dark mode
$primary-color: #000;
$secondary-color: #151e29;
$text-color: #fff;

$font-family-heading: "Varela Round", sans-serif;
$font-family: "Arvo", serif;

$sidebar-width: 280px;
