.contact {
  .contact-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;

    @include media-breakpoint-down(md) {
      & {
        grid-template-columns: 1fr;
        row-gap: 1rem;
      }
    }
    .contact-details {
      display: flex;
      align-items: center;
    }
  }
}

@import "contactform";
