.navbar-mobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  z-index: 1000;
  @include media-breakpoint-down(md) {
    & {
      display: flex;
    }
  }
  @include media-breakpoint-down(sm) {
    background-color: $primary-color;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
  }
  .name {
    font-size: 1.75rem;
    font-weight: bold;
    visibility: hidden;
    margin: 0;
    @include media-breakpoint-down(sm) {
      visibility: initial;
    }
  }
  // Hamburger menu icon animated
  .hamburger {
    display: inline-block;
    cursor: pointer;
    padding: 0.5rem;
    &:hover {
      opacity: 0.8;
    }
    .bar1,
    .bar2,
    .bar3 {
      width: 35px;
      height: 3px;
      background-color: $text-color;
      border-radius: 1rem;
      margin: 8px 0;
      transition: 0.4s;
    }
    &.change {
      .bar1 {
        transform: rotate(-45deg) translate(-8px, 8px);
      }
      .bar2 {
        opacity: 0;
      }
      .bar3 {
        transform: rotate(45deg) translate(-8px, -8px);
      }
    }
  }
}
