.business-card {
  //Variables
  $background-primary: #fff;
  $text-color: #000;
  $text-color-alt: rgba($text-color, 0.6);
  $divider-color: rgba($text-color, 0.4);
  $padding-card: 1em;

  width: 350px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: $background-primary;
  color: $text-color;
  font-size: 125%;
  font-family: $font-family;
  position: relative;
  padding: $padding-card;

  @include media-breakpoint-down(lg) {
    width: 300px;
    font-size: 105%;
  }

  @include media-breakpoint-down(md) {
    max-width: 350px;
    font-size: 110%;
  }

  .header {
    display: flex;
    justify-content: space-between;
    .details {
      .name {
        font-family: $font-family-heading;
        font-size: 1em;
        line-height: 1;
      }
      .profession {
        font-size: 0.75em;
        color: $text-color-alt;
      }
    }
    .logo {
      img {
        max-width: 6em;
      }
    }
  }

  .contacts {
    .contact-item {
      font-size: 0.75em;
      a {
        color: $text-color-alt;
        &:hover {
          color: rgba($text-color, 0.8);
        }
      }
      b {
        color: $text-color;
        font-family: $font-family-heading;
        margin-right: 0.25em;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    .place {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .company {
        font-size: 0.75em;
        font-family: $font-family-heading;
      }
      .address {
        font-size: 0.5em;
        color: $text-color-alt;
      }
    }
    .qrcode {
      position: absolute;
      bottom: $padding-card;
      right: $padding-card;
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
          font-size: 0.4em;
          color: $text-color-alt;
          transition: 0.5s;
        }
        svg {
          max-width: 3em;
          max-height: 3em;
        }
        &:hover {
          span {
            color: rgba($text-color, 0.8);
          }
        }
      }
    }
  }

  .divider {
    margin: 0.5rem 0;
    width: 1em;
    height: 2px;
    border-radius: 1px;
    background-color: $divider-color;
  }
}
