.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: $sidebar-width;
  background: lighten($primary-color, 6);
  color: $text-color;
  text-align: center;
  padding: 1rem;
  z-index: 1001;
  transition: 0.5s;
  overflow-y: auto;
  box-shadow: 2px 0px 6px 0px rgba(0, 0, 0, 0.1);
  @include media-breakpoint-down(md) {
    & {
      margin-left: -$sidebar-width;
    }
  }
  .name {
    font-size: 1.5rem;
  }
  .avatar {
    max-width: 160px;
    padding: 0.75rem 0;
  }
  .bio {
    font-size: 1rem;
  }

  .nav {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 1.5rem 0;
    a {
      font-size: 1rem;
      font-weight: bold;
      padding: 0.5rem;
      text-decoration: none;
      color: rgba($text-color, 0.6);
      transition: 0.25s color;

      &:hover {
        color: $text-color;
      }
      &.active {
        color: $text-color;
      }
    }
  }

  .contacts {
    ul {
      li {
        margin-bottom: 0.5rem;
        text-align: left;
        a {
          display: flex;
          align-items: center;
          span {
            color: rgba($text-color, 0.6);
            transition: 0.5s;
            &:hover {
              color: $text-color;
            }
          }
        }
      }
    }
  }
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: 0.5s;
  cursor: pointer;
  visibility: hidden;
  @include media-breakpoint-up(lg) {
    opacity: 0 !important;
  }
}
