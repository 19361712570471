.work-item {
  display: grid;
  grid-template-columns: auto 4fr;
  grid-column-gap: 1rem;
  align-items: center;
  border-bottom: 1px solid $resume-divider-color;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  &:last-child {
    border-bottom: none;
  }
  .work-icon {
    max-width: 4rem;
    border-radius: 0.5rem;
    @include media-breakpoint-down(sm) {
      & {
        max-width: 3rem;
      }
    }
  }
  .work-content-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    &.no-description {
      cursor: initial;
      .arrow-toggle {
        display: none;
      }
    }
    .work-content {
      .work-title {
        font-family: $font-family-heading;
        letter-spacing: 0.05rem;
        font-weight: bold;
        font-size: 1.1rem;
        @include media-breakpoint-down(sm) {
          & {
            font-size: 1rem;
          }
        }
      }
      .work-subtitle {
        font-size: 0.85rem;
      }
      .work-company {
        font-size: 0.85rem;
      }
      .work-duration {
        display: flex;
        color: $resume-text-color-alt;
        font-size: 0.75rem;
        @include media-breakpoint-down(md) {
          & {
            flex-direction: column;
          }
        }
        .work-time {
          color: $resume-text-color;
          font-weight: bold;
          border-left: 1px solid $resume-text-color-alt;
          padding-left: 0.5rem;
          margin-left: 0.5rem;
          @include media-breakpoint-down(md) {
            & {
              border-left: none;
              padding-left: 0;
              margin-left: 0;
            }
          }
        }
      }
      .work-place {
        color: $resume-text-color-alt;
        font-size: 0.65rem;
      }
    }
    .work-toggle {
      padding: 1.5rem;
      @include media-breakpoint-down(sm) {
        & {
          padding: 0.5rem;
        }
      }
      .arrow-toggle {
        .bar1,
        .bar2 {
          width: 1rem;
          height: 3px;
          border-radius: 1px;
          background-color: $resume-text-color;
          opacity: 1;
          transition: 0.2s transform, 0.2s opacity;
        }
        .bar2 {
          margin-top: -3px;
          transform: rotate(90deg);
        }
        &.active {
          .bar2 {
            transform: rotate(0deg);
            opacity: 0;
          }
        }
      }
    }
  }
  .work-description {
    max-height: 0;
    overflow: hidden;
    grid-column: 2;
    font-size: 0.75rem;
    line-height: 1.25rem;
    transition: max-height 0.2s ease-out;
    @include media-breakpoint-down(sm) {
      & {
        grid-column: span 2;
      }
    }
    p {
      color: $resume-text-color;
      padding-top: 0.5rem;
      margin: 0;
    }
  }
}
