@media print {
  #resume {
    border: none;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .work-time {
      display: none;
    }
  }
}
