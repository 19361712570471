.testimonials {
  display: flex;
  overflow-x: scroll;
  &:active {
    cursor: grab;
  }
  .testimonial {
    height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.25rem;
    background: lighten($primary-color, 6);
    border: 1px solid lighten($primary-color, 15);

    @include media-breakpoint-down(sm) {
      & {
        padding: 1.5rem;
      }
    }
    .description {
      line-height: 1.6;
      font-size: 0.9rem;

      @include media-breakpoint-down(sm) {
        & {
          font-size: 1.05rem;
        }
      }
    }
    .source {
      display: flex;
      align-items: center;
      .avatar {
        max-width: 3rem;
        margin-right: 0.75rem;
      }
      .name {
        font-family: $font-family-heading;
        @include media-breakpoint-down(sm) {
          & {
            font-size: 1.25rem;
          }
        }
      }
      .job {
        font-size: 0.85rem;
        color: rgba($text-color, 0.6);
      }
    }
  }
}
