.about-me {
  .name {
    font-weight: bold;
    margin-top: 2rem;
    @include media-breakpoint-down(sm) {
      & {
        display: none;
        margin-top: 0;
      }
    }
  }
  .tagline {
    font-size: 1.5rem;
    font-weight: 100;
    margin-bottom: 1.25rem;
    @include media-breakpoint-down(sm) {
      & {
        margin-bottom: 0.5rem;
      }
    }
  }
  .description {
    color: rgba($text-color, 0.7);
    margin-bottom: 1.5rem;
  }
}
