.contact-form {
  .contact-input {
    display: block;
    width: 100%;
    height: 2.5rem;
    padding: 0.5rem 0.85rem;
    margin-bottom: 1rem;
    color: #5c5c5c;
    border: 1px solid #9c9c9c;
    border-radius: 0.25rem;
    outline: none;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:focus {
      border-color: $text-color;
      box-shadow: 0 0 0 0.2rem rgba($text-color, 0.5);
    }
  }
  textarea.contact-input {
    height: auto;
  }
}
