.project {
  .project-navigation {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    .project-page-title {
      font-size: 1.5rem;
      font-family: $font-family-heading;
      margin-left: 1rem;
    }
    .button {
      padding: 0.25rem 0.75rem;
    }
  }
  .project-info {
    background-color: lighten($primary-color, 6);
    border-radius: 1rem;
    overflow: hidden;
    // border: 2px solid rgba(255, 255, 255, 0.1);

    @include media-breakpoint-down(md) {
      & {
        grid-template-columns: 1fr;
      }
    }

    .project-details {
      display: flex;
      justify-content: space-between;
      padding: 1rem 1.5rem;
      font-size: 100%;

      @include media-breakpoint-down(md) {
        & {
          flex-direction: column;
        }
      }

      .title {
        font-family: $font-family-heading;
        font-size: 2em;
      }
      .subtitle {
        color: rgba($text-color, 0.6);
        margin-bottom: 1rem;
      }
      .project-meta {
        .client,
        .website {
          font-size: 1.25em;
          b {
            font-weight: bold;
            letter-spacing: normal;
            font-family: $font-family-heading;
            color: rgba($text-color, 0.6);
          }
        }
      }
      .technologies {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        flex-wrap: wrap;

        @include media-breakpoint-down(md) {
          & {
            margin-top: 1rem;
          }
        }
        .technology {
          .name {
            font-size: 1.25em;
            font-family: $font-family-heading;
            color: rgba($text-color, 0.6);
          }
          .values {
            font-size: 1em;
            letter-spacing: 1px;
          }
        }
      }
    }
    .project-image {
      padding-top: 55%;
      background-position: top left;
      background-size: cover;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }
  .project-description {
    padding: 1rem 0;
  }
}
