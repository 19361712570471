.overview {
  .title {
    font-weight: bold;
  }
  .subtitle {
    color: rgba($text-color, 0.7);
    margin-bottom: 1.5rem;
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 3rem;
    @include media-breakpoint-down(md) {
      & {
        grid-template-columns: repeat(3, 1fr);
      }
    }
    @include media-breakpoint-down(sm) {
      & {
        grid-template-columns: repeat(3, 1fr);
      }
    }
    .grid-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .text {
        margin-top: 1rem;
        font-family: $font-family-heading;
        font-weight: bold;
        text-align: center;
        @include media-breakpoint-down(sm) {
          & {
            font-size: 0.75rem;
          }
        }
      }
    }
  }
}
